import {
  GetBookingRequest,
} from "@desanaio/public-hops-grpc-web/dist/desana/host/booking/v1/booking_pb";
import providers from "../../ra-data-desana/providers";
import * as storage from "../../utils/storage";

const fetchResourceBookingId = async (id) => {
  const { Bookings } = providers;
  const request = new GetBookingRequest();
  request.setId(id.split(",")[0]);
  const response = await Bookings.getBooking(request, {
    authorization: storage.get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }

  const { resourceBookingId } = response.toObject();

  return {
    resourceBookingId,
  };
};

export default fetchResourceBookingId;