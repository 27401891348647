import { ListResourcesRequest } from "@desanaio/public-hops-grpc-web/dist/desana/host/resource/v1/resource_pb";
import {
  Paging,
  Sorter,
} from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/request_options_pb";
import providers from "../providers";
import { fromDatabase } from "./mappers/from-database";
import * as storage from "../../utils/storage";
import { snakeCase } from "lodash";
import { getSpacesForSpaceRole } from "../../utils/auth";
import { SpaceAdminRole } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";

export const listResources = async (params, resourceTypes) => {
  const { Resources } = providers;
  const request = new ListResourcesRequest();

  const currentOrg = storage.get("currentOrganisation");
  request.setOwner(currentOrg.organisation.id);

  const viewSpacePermissions = getSpacesForSpaceRole(currentOrg, [
    SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_DETAILS,
    SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_DETAILS,
    SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_BOOKINGS,
    SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_BOOKINGS,
    SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_PAYMENT_DETAILS,
  ]);

  // If we don't have permission to view resources
  if (viewSpacePermissions === false) {
    throw new Error("Permission denied");
  }

  if (viewSpacePermissions !== true) {
    request.setSpacesList(viewSpacePermissions);
  }

  if (params.pagination) {
    const { page, perPage } = params.pagination;
    const pagination = new Paging();
    pagination.setFrom((page - 1) * perPage);
    pagination.setSize(perPage);
    request.setPaging(pagination);
  }

  if (
    params.sort &&
    params.sort.field !== "id" &&
    (!params.filter || !params.filter.search)
  ) {
    const { field, order } = params.sort;
    const sorter = new Sorter();
    sorter.setKey(snakeCase(field));
    sorter.setOrder(order);
    request.setSortList([sorter]);
  }

  if (params.filter) {
    if (params.filter.spaces) {
      request.setSpacesList(params.filter.spaces);
    }
  }

  if (params.ids && params.ids.length > 0) {
    request.setIdsList(params.ids);
    request.setPaging(new Paging().setSize(params.ids.length));
  }

  if (resourceTypes?.length) {
    request.setTypeList(resourceTypes);
  }

  const response = await Resources.listResources(request, {
    authorization: localStorage.getItem("desana-tkn"),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }
  return {
    total: response.getCount(),
    data: response.getResultsList().map((r) => fromDatabase(r.toObject())),
  };
};
