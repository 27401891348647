import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRedirect } from "react-admin";
import fetchResourceBookingId from "./fetchResourceBookingId";

const LegacyBookingRedirect = () => {

  const redirect = useRedirect();
  const { id } = useParams();


  useEffect(() => {
    const fetch = async () => {
      try {
        const { resourceBookingId } = await fetchResourceBookingId(id);


        if (resourceBookingId) {
          redirect(`/hotDeskBookings/${resourceBookingId}/show`);
        } else {
          console.error("No resource booking ID found");
        }
      } catch (error) {
        console.error("Error fetching resource booking ID:", error);
      }
    };

    fetch();
  }, [id, redirect]);

  return null;
};

export default LegacyBookingRedirect;