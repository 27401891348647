import { Card, Grid, makeStyles, Typography } from "@material-ui/core";
import { cloneElement, useState } from "react";
import {
  Datagrid,
  FunctionField,
  ListBase,
  TextField,
  useGetList,
} from "react-admin";
import { hoursToHoursAndMinutes } from "../common/hours-to-hours-and-minutes";
import { CurrencyField } from "../components/CurrencyField";
import LocalDateTimeField from "../components/LocalDateTimeField";
import RespondToBookingRequest from "../components/RespondToBookingRequest";
import UserProfileQuickPreviewButton from "../components/UserProfileQuickPreviewButton";
import Attendees from "../resource-bookings/Attendees";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 320,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  paperGrid: {
    height: 320,
    padding: theme.spacing(2),
    // color: theme.palette.text.secondary,
    display: "grid",
    alignContent: "baseline",
  },
  scrollable: {
    display: "grid",
    overflow: "auto",
  },
  arrayField: {
    "& div[class^='Labeled']": {
      padding: 0,
    },
    "& div[class^='MuiFormControl']": {
      display: "flex",
      margin: 0,
    },
    "& thead tr": {
      height: "30px",
    },
  },
}));

export const PendingResourceBookings = () => {
  const { ids, loading, error } = useGetList("pendingResourceBookings");
  const [idsRespondedTo, setIdsRespondedTo] = useState([]);

  if (loading || error || ids.length === 0) {
    return null;
  }

  const filteredIds = ids.filter((id) => !idsRespondedTo.includes(id));

  if (filteredIds.length === 0) {
    return null;
  }

  return (
    <Grid item xs={12} lg={12} xl={6}>
      <PendingResourceBookingsList
        bulkActionButtons={false}
        exporter={false}
        actions={null}
      >
        <Datagrid>
          <UserProfileQuickPreviewButton
            source="user"
            sortable={false}
            label=""
          />

          <FunctionField
            label="Where?"
            render={(record) => (
              <TextField
                record={{
                  name: `${record?.resource?.name}, ${record?.space?.name}`,
                }}
                source="name"
                label="Where?"
                sortable={false}
              />
            )}
          />

          <LocalDateTimeField
            source="startsAt"
            label="When?"
            showTime
            showDate
            sortable={false}
            options={{
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            }}
            timezoneSource="timezone"
          />
          <TextField label="What?" source="resource.resourceType.name" />
          <FunctionField
            label="Duration"
            render={(record) => hoursToHoursAndMinutes(record.hours)}
          />
          <FunctionField
            label="Number of attendees"
            render={(record) => (
              <Attendees bookingSlot={record} resource={record.resource} />
            )}
          />
          <FunctionField
            label="Estimated payout"
            render={(record) => (
              <CurrencyField source="cost" spaceId={record.space?.id} />
            )}
          />
          <RespondToBookingRequest
            label="Accept/Reject?"
            onSuccess={(id) => {
              setIdsRespondedTo([...idsRespondedTo, id]);
            }}
          />
        </Datagrid>
      </PendingResourceBookingsList>
    </Grid>
  );
};

const PendingResourceBookingsList = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <ListBase
      resource="pendingResourceBookings"
      basePath="/pendingResourceBookings"
      {...props}
    >
      <Card className={classes.paperGrid} variant="outlined">
        <Typography variant="h6" gutterBottom>
          🚨 Pending Booking Requests
        </Typography>
        <div className={classes.scrollable}>
          {cloneElement(children, {
            hasBulkActions: props.bulkActionButtons !== false,
          })}
        </div>
      </Card>
    </ListBase>
  );
};
