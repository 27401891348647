const RESOURCE_TYPES = {
  HOT_DESK: import.meta.env.VITE_RESOURCE_TYPE_HOT_DESK,
  // This is to support the unique setup Utility Warehose have with thesans.london
  HOT_DESK_INTERNAL: import.meta.env.VITE_RESOURCE_TYPE_HOT_DESK_INTERNAL,
  MEETING_ROOM: import.meta.env.VITE_RESOURCE_TYPE_MEETING_ROOM,
  PRIVATE_OFFICE: import.meta.env.VITE_RESOURCE_TYPE_PRIVATE_OFFICE,
  EVENT_SPACE: import.meta.env.VITE_RESOURCE_TYPE_EVENT_SPACE,
};

export { RESOURCE_TYPES };
