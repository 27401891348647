import { toLower, startCase } from "lodash";

export const enumToSelectionOptions = (
  enumOptions,
  fieldPrefix,
  excludeUnspecified = true
) =>
  Object.keys(enumOptions)
    .filter(
      (fieldName) => !excludeUnspecified || !fieldName.includes("UNSPECIFIED")
    )
    .map((fieldName) => ({
      id: enumOptions[fieldName],
      name: startCase(toLower(fieldName.replace(fieldPrefix, ""))),
    }));
